import axios from "axios";
import { BASE_URL } from "../BaseUrl";

// Create an Axios instance with default headers
const accessToken = JSON.parse(localStorage.getItem("token"));
console.log("accessToken", accessToken);
let headers = {
  "Content-Type": "application/json", // Adjust the content type as needed
};
if (accessToken != null) {
  headers["Authorization"] = `Bearer ${accessToken}`; // Add the token to the 'Authorization' header
}
const axiosInstance = axios.create({
  baseURL: "https://api-sample1-ims.techdarshak.com/api/v1", // Replace with your API base URL
  headers: headers,
});
export default axiosInstance;
// "http://localhost:8080"
