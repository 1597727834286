import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./routes/Home";
import Login from "./routes/Login";
import "./index.css";
import Dashboard1 from "./routes/Dashboard1";
import SharedLayout from "./pages/SharedLayout";
import Home2 from "./routes/Home2";
import InventoryLog from "./pages/InventoryLogs";
import SiteInventoryLog from "./pages/SiteInventoryLog";
import SiteInventory from "./pages/SiteInventory";
import Category from "./pages/Category";
import SiteList from "./pages/SiteList";
import UnitList from "./pages/Unit";
import ProductList from "./pages/ProductsList";
import Users from "./pages/Users";
import Inventory from "./pages/Inventory";
import InwardLog from "./pages/InwardLog";
import OutwardLog from "./pages/OutwardLog";
import Folder from "./pages/Folder";
import File from "./pages/File";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/home2" element={<Home2 />} />
      <Route path="/" element={<SharedLayout />}>
        <Route index element={<Home />} />

        <Route path="/product-list" element={<ProductList />} />

        <Route path="/sites-list" element={<SiteList />} />

        <Route path="/inventory" element={<Inventory />} />

        <Route path="/inward-log" element={<InwardLog></InwardLog>} />

        <Route path="/outward-log" element={<OutwardLog></OutwardLog>} />

        <Route path="/site-inventory-log" element={<SiteInventoryLog />} />
        <Route path="/site-inventory" element={<SiteInventory />} />

        <Route path="/category-list" element={<Category></Category>} />

        <Route path="/units-list" element={<UnitList></UnitList>} />

        <Route path="/users-list" element={<Users></Users>} />

        <Route path="/folders" element={<Folder></Folder>} />

        <Route path="/files/:id" element={<File></File>} />

      </Route>
    </Routes>
  );
}

export default App;
