import React, { useState, useEffect } from "react";
import "../../src/Table.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../components/PaginationComponent";
import axiosInstance from "../services/axios";
import "../pages/Addsite.css";
import { FaTimes, FaAngleDown, FaRedoAlt } from "react-icons/fa";
import img1 from "../assests/empty1.png";
import Loader from "../components/Loader";
import NoData from "../components/NoData";
import { ToastContainer, toast } from "react-toast";
import Select from "react-select";
import * as XLSX from "xlsx";

let PageSize = 10;

const SiteInventory = () => {
  const navigate = useNavigate();

  const [sitesList, setSitesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [siteData, setSiteData] = useState({
    stock_item_id: null,
    qty: null,
    log_type: "Plus",
    site_id: null,
  });
  const [productOptions, setProductOptions] = useState([]);
  const [logTypeOptions, setLogTypeOptions] = useState(["Plus", "Minus"]);
  const [sitesOptions, setSitesOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (siteData.name == null || siteData.name == "") {
      setErrors({ ...errors, [name]: "" });
    }
    setSiteData({ ...siteData, [name]: value });
  };
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [siteToSend, setSiteToSend] = useState(null);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    stock_item_id: null,
  });
  const [selectFilter, setSelectedFilter] = useState(null);
  const [filterSite, setFilterSite] = useState(null);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    fetchAllInventory();
  }, [currentPage]);

  useEffect(() => {
    fetchAllProducts();
    fetchAllInventory();
  }, []);

  useEffect(() => {
    console.log("selected site : ", siteData);
  }, siteData);

  const fetchAllInventory = async () => {
    setSitesList([]);
    setLoader(true);
    let url = `/siteinventory/getAllSiteInventory?limit=${PageSize}&pageNo=${currentPage}`;
    if (
      filterOptions?.["stock_item_id"] != null &&
      filterOptions?.["stock_item_id"] != ""
    ) {
      url = `${url}&stock_item_id=${filterOptions["stock_item_id"]}`;
    }
    if (
      filterOptions?.["site_id"] != null &&
      filterOptions?.["site_id"] != ""
    ) {
      url = `${url}&site_id=${filterOptions["site_id"]}`;
    }
    try {
      const unitResponse = await axiosInstance.get(url);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (unitResponse.data["success"] === true) {
        setSitesList(unitResponse.data.data.siteinventory);
        setTotalCount(unitResponse.data.data.count);
      }
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const fetchAllProducts = async () => {
    setProductOptions([]);
    setLoader(true);
    try {
      const response = await axiosInstance.get(`/inventory/getAllInventory`);
      const siteResponse = await axiosInstance.get(`/sites/getAllSites`);
      if (response.data["success"] === true) {
        console.log(response.data.data.products); // handle the response from the server
        const options = [];
        response.data.data.inventory.map((item, index) => {
          options.push({
            value: item?.stock_details?.id,
            label: item?.stock_details?.name,
          });
        });
        setProductOptions(options);
      }
      if (siteResponse.data["success"] === true) {
        const options = [];
        siteResponse.data.data.sites.map((item, index) => {
          options.push({ value: item?.id, label: item?.name });
        });
        setSitesOption(options);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  useEffect(() => {
    console.log("Error : ", errors);
  }, [errors]);

  // function formatDate(date) {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return new Date(date).toLocaleDateString(undefined, options);
  // }

  const deleteSelectedSite = async () => {
    setLoader(true);
    try {
      const response = await axiosInstance.delete(
        `/siteinventory/deleteSiteInventory/${selectedSite.id}`
      );
      if (response.data["success"] === true) {
        console.log(response); // handle the response from the server
        fetchAllInventory();
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let apiUrl = null;
    let isError = null;
    if (Object.keys(siteData).length > 0) {
      Object.keys(siteData).map((item, index) => {
        if (item != "created_at" && item != "updated_at") {
          if (siteData?.[item] == null || siteData?.[item] == "") {
            const { name, value } = {
              name: item,
              value: `${item} is required!`,
            };
            setErrors({ ...errors, [name]: value });
          }
        }
      });
    } else {
      setErrors({
        stock_item_id: "Name is required",
        qty: "Product Quantity is required",
        log_type: "Name is required",
        site_id: "Name is required",
      });
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      return;
    }
    if (Object.keys(errors)?.length > 0) {
      Object.keys(errors).map((item, index) => {
        if (errors?.[item] != null || errors?.[item] != "") {
          const { name, value } = {
            name: item,
            value: `${item} is required!`,
          };
          setErrors({ ...errors, [name]: value });
          setTimeout(() => {
            setLoader(false);
          }, 1000);
          return;
        }
      });
    }
    if (selectedProduct) {
      siteData["stock_item_id"] = selectedProduct["value"];
    }
    if (siteToSend) {
      siteData["site_id"] = siteToSend["value"];
    }
    if (siteData["log_type"] == "Minus") {
      siteData["qty"] = -siteData["qty"];
    }
    setLoader(true);
    apiUrl = "/siteinventory/addSiteInventory";

    try {
      const response = await axiosInstance.post(apiUrl, siteData);
      if (response.data["success"] == true) {
        fetchAllInventory();
        setSelectedSite({});
        setSiteData({});
        setOpenForm(false);
        setSiteToSend(null);
        setSelectedProduct(null);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 412) {
        console.log(error?.response?.data?.data);
        if (typeof error?.response?.data?.data == "string") {
          toast.error(error?.response?.data?.data);
          if (error?.response?.data?.data?.includes("Product")) {
            setErrors({
              stock_item_id: error?.response?.data?.data,
            });
          } else if (
            error?.response?.data?.data?.includes("Product Quantity")
          ) {
            setErrors({
              qty: error?.response?.data?.data,
            });
          }
        } else {
          setErrors(error?.response?.data?.data);
        }
      }
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const customstyles = {
    // control represent the select component
    control: (provided) => ({
      ...provided,
      width: "95%",
      height: "50px",
      border: "1px solid",
      borderRadius: "5px",
    }),
  };

  useEffect(() => {
    console.log("Filter Options ", filterOptions);
    setCurrentPage(0);
    fetchAllInventory();
  }, [filterOptions]);

  const handleFilterChange = (e, type) => {
    if (type === "product") {
      setFilterOptions({ ...filterOptions, stock_item_id: e?.value });
      setSelectedFilter(e);
    }
    if (type === "site") {
      setFilterOptions({ ...filterOptions, site_id: e?.value });
      setFilterSite(e);
    }
  };


  //export data to excel

  // stock_item_id: null,
  //   qty: null,
  //   log_type: "Plus",
  //   site_id: null,
  const fetchAllInventoryData = async () => {
    try {
      let allInventoryData = [];
      const response = await axiosInstance.get(`/siteinventory/getAllSiteInventory?limit=${PageSize}&pageNo=0`);
      
      if (response.data.success === true) {
        allInventoryData = response.data.data.inventory.map(item => ({
          id: item.id,
          productName: item.stock_details.name, 
          quantity: item.qty
        }));

        const totalPages = Math.ceil(totalCount / PageSize);
        for (let page = 1; page <= totalPages; page++) {
          const nextPageResponse = await axiosInstance.get(`/inventory/getAllInventory?limit=${PageSize}&pageNo=${page}`);
          if (nextPageResponse.data.success === true) {
            const nextPageData = nextPageResponse.data.data.inventory.map(item => ({
              id: item.id,
              productName: item.stock_details.name, 
              quantity: item.qty
            }));
            allInventoryData = allInventoryData.concat(nextPageData);
          }
        }
      }

      return allInventoryData;
    } catch (error) {
      console.error("Error fetching all inventory data:", error);
      throw error;
    }
  };

  const exportAllToExcel = async () => {
    try {
      const allData = await fetchAllInventoryData();
      
      if (allData.length === 0) {
        toast.error("No data available to export.");
        return;
      }

      const worksheetData = allData.map(({ id, productName, quantity }) => ({
        ID: id,
        "Product Name": productName,
        Quantity: quantity
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Inventory Data");
      XLSX.writeFile(workbook, "inventory_data.xlsx");
    } catch (error) {
      console.error("Error exporting all data to Excel:", error);
      toast.error("Failed to export all data to Excel.");
    }
  };
//done exporting data to excel func

  return (
    <>
      <h1 className="heading "></h1>
      <div
        className="relative overflow-x-auto sm:rounded-lg"
        style={{ width: "100%", padding: "20px" }}
      >
        {loader ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className="bg-white tableDiv">
              <div className="">
                {/* <Link
              to="/add-category"
              className="text-white bg-blue-700  font-medium  text-sm px-6 py-4 text-center b1"
            >
              Add Sites
            </Link> */}
                <button
                  onClick={() => {
                    setOpenForm(true);
                    setSiteData({
                      stock_item_id: productOptions[0]?.id,
                      site_id: sitesOptions[0]?.id,
                      qty: null,
                      log_type: "Plus",
                    });
                    setSelectedSite({});
                    setSelectedProduct(null);
                    setSiteToSend(null);
                  }}
                  className="btn-sm float-right"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "5px",
                    alignItems: "center",
                    width: "100px",
                    background: "#814f68",
                  }}
                >
                  <span style={{ fontSize: "24px" }}>+</span> Add
                  {/* Add New Inventory */}
                </button>
                <button onClick={exportAllToExcel} className="btn-sm float-right" style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      alignItems: "center",
                      width: "100px",
                      background:"#814f68",
                      fontSize:"18px"
                    
                    }}>Export to excel</button>
              </div>
              <div className="ml-4">
                <h1 className=" text-2xl py-6">Site Inventory</h1>
              </div>
              <div
                className="flex row align-item-center bg-gray-50 px-5"
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  height: "30px",
                  borderBottom: "5px solid #fff",
                }}
              >
                <button
                  className=""
                  onClick={() => {
                    setFilterOptions({});
                    setSelectedFilter(null);
                    setFilterSite(null);
                  }}
                  type="reset"
                >
                  <FaRedoAlt />
                </button>
                <button
                  className=""
                  onClick={() => {
                    setShowFilterOptions(!showFilterOptions);
                  }}
                  type="reset"
                >
                  <FaAngleDown />
                </button>
              </div>
              {showFilterOptions ? (
                <div
                  className="flex row px-5 align-item-center"
                  style={{ height: "80px", marginBottom: "20px" }}
                >
                  <div className="colFour">
                    <h6 className="small">Select Product</h6>
                    <Select
                      defaultValue={selectFilter}
                      value={selectFilter}
                      onChange={(e) => {
                        handleFilterChange(e, "product");
                      }}
                      options={productOptions}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "blue",
                          primary: "black",
                        },
                      })}
                      styles={customstyles}
                    />
                  </div>
                  <div className="colFour">
                    <h6 className="small">Select Site</h6>
                    <Select
                      defaultValue={filterSite}
                      value={filterSite}
                      onChange={(e) => {
                        handleFilterChange(e, "site");
                      }}
                      options={sitesOptions}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "blue",
                          primary: "black",
                        },
                      })}
                      styles={customstyles}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {sitesList.length > 0 ? (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  bg-white">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        {/* <input type="checkbox"></input> */}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Product name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Site
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Quantity
                      </th>

                      <th scope="col" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sitesList.length > 0 ? (
                      Object.values(sitesList)?.map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        >
                          <td scope="col" className="px-6 py-3">
                            {/* <input type="checkbox"></input> */}
                            {index + 1}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item?.stock_details?.name} <br></br>
                            {item?.stock_details.item_code}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item?.site_details ? item?.site_details?.name : 'N/A'}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item?.qty}
                          </td>
                          <td className="px-6 py-4">
                            <div className="row align-item-center justify-space-space">
                              <span
                                onClick={() => {
                                  setSelectedProduct({
                                    value: item?.stock_details?.id,
                                    label: item?.stock_details?.name,
                                  });
                                  setSiteData({
                                    stock_item_id: item?.stock_item_id,
                                    qty: 0,
                                    site_id: item?.site_id,
                                  });
                                  setSiteToSend({
                                    value: item?.site_details?.id,
                                    label: item?.site_details?.name,
                                  });
                                  setSelectedSite(item);
                                  setOpenForm(true);
                                }}
                                className="font-medium text-[#814f68] -600 dark:text-blue-500 hover:underline ml-4"
                              >
                                Edit
                              </span>

                              <span
                                className="font-medium text-red-600 dark:text-blue-500 hover:underline ml-4"
                                onClick={() => {
                                  setSubmissionModal(true);
                                  setSelectedSite(item);
                                }}
                              >
                                Delete
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoData />
                    )}
                  </tbody>
                </table>
              ) : (
                <NoData></NoData>
              )}
            </div>
            {sitesList.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  className="mb-2"
                  totalPosts={totalCount}
                  postsPerPage={PageSize}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        {submissionModal && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-20 z-50"
                style={{ width: "800px", maxWidth: "100%" }}
              >
                <div className="p-4 text-center sm:p-0 res">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5 res">
                      <div className="flex justify-between items-center res">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          Please Confirm
                        </h2>
                        {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                      </div>
                      <div>
                        <div className="flex py-10 justify-between items-center res">
                          <p className="text-[16px] font-bold helvetica not-italic mb-4 text-red-600">
                            Are you sure you want to delete?
                          </p>
                          <div className="flex gap-10 pr-2">
                            <button
                              className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                              onClick={async () => {
                                setSubmissionModal(false);
                                // nextStep();
                                await deleteSelectedSite(true);
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => {
                                setSubmissionModal(false);
                              }}
                              className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {openForm && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-20 z-50"
                style={{ width: "600px", maxWidth: "90%" }}
              >
                <div className="p-4 text-center sm:p-0">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5">
                      <div className="flex justify-between items-center">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          {Object.keys(selectedSite).length > 0
                            ? "Update Site Inventory"
                            : "Add Site Inventory"}
                        </h2>
                        {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                        <button
                          className=""
                          onClick={() => {
                            setOpenForm(false);
                            setSiteData({});
                            setSelectedSite({});
                          }}
                          type="reset"
                        >
                          <FaTimes />
                        </button>
                      </div>
                      <form className="addsite" onSubmit={handleSubmit}>
                        <h6 className="small">
                          Select Product <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <Select
                          value={selectedProduct}
                          onChange={setSelectedProduct}
                          options={productOptions}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "blue",
                              primary: "black",
                            },
                          })}
                          isDisabled={
                            Object.keys(selectedSite).length > 0 ? true : false
                          }
                          styles={customstyles}
                        />
                        {errors.stock_item_id && (
                          <p className="error-text">{errors.stock_item_id}</p>
                        )}
                        <h6 className="small">
                          Select Site <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <Select
                          defaultValue={siteToSend}
                          value={siteToSend}
                          onChange={setSiteToSend}
                          options={sitesOptions}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "blue",
                              primary: "black",
                            },
                          })}
                          isDisabled={
                            Object.keys(selectedSite).length > 0 ? true : false
                          }
                          styles={customstyles}
                        />
                        <h6 className="small">
                          Product Quantity{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <input
                          className="textbox"
                          type="number"
                          name="qty"
                          placeholder="Enter Product Quantity"
                          min={0}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={siteData.qty}
                        />
                        {errors.qty && (
                          <p className="error-text">{errors.qty}</p>
                        )}
                        <h6 className="small">
                          Select Log Type{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <select
                          className="textbox"
                          placeholder="Please Select Log Type"
                          name="log_type"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={siteData?.log_type}
                        >
                          {logTypeOptions ? (
                            Object.values(logTypeOptions)?.map(
                              (item, index) => (
                                <option value={item}>{item}</option>
                              )
                            )
                          ) : (
                            <span>N/A</span>
                          )}
                        </select>

                        <div className="flex w-100 gap-5 justify-space-space px-10 mt-4">
                          <button
                            className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#383838]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                            type="submit"
                          >
                            {Object.keys(selectedSite).length > 0 ? (
                              <span>Update</span>
                            ) : (
                              <span>Submit</span>
                            )}
                          </button>
                          <button
                            onClick={() => {
                              setOpenForm(false);
                              setSiteData({});
                              setSelectedSite({});
                            }}
                            type="reset"
                            className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                          >
                            Close
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <ToastContainer position="top-center" autoClose={2000} />

        {/* Same as */}
        {/* <ToastContainer /> */}
      </div>
    </>
  );
};

export default SiteInventory;
