import React, { useState, useEffect } from "react";
import "../../src/Table.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../components/PaginationComponent";
import axiosInstance from "../services/axios";
import "../pages/Addsite.css";
import Inventory from "./Inventory";
import InventoryLog from "./InventoryLogs";
import Loader from "../components/Loader";

let PageSize = 10;

const InwardLog = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  return (
    <>
      {loader ? (
        <div>
          <Loader />
        </div>
      ) : (<>
          
      </>
      )}
      <InventoryLog type={'increase'} />
      
    </>
  );
};

export default InwardLog;
