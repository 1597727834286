import React, { useEffect, useState, useMemo } from "react";
import "../../src/Table.css";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../services/axios";
import Pagination from "../components/PaginationComponent";
import "../pages/Addsite.css";
import { ToastContainer, toast } from "react-toast";
import { FaTimes } from "react-icons/fa";
import { CheckPermission } from "../components/CheckPermissions";
import Loader from "../components/Loader";
import img1 from "../assests/empty1.png";

import NoData from "../components/NoData";

let PageSize = 10;

const Category = () => {
  const navigate = useNavigate();

  const [sitesList, setSitesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [openForm, setOpenForm] = useState(false);

  const [categoryData, setCategoryData] = useState({
    name: "",
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  // const handleChang = (e) => {
  //   const { name, value } = e.target;

  // Validate the input here

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (categoryData.name == null || categoryData.name == "") {
      setErrors({ ...errors, [name]: "" });
    }

    setCategoryData({ ...categoryData, [name]: value });
    // setErrors(newErrors);
  };

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    fetchAllSites();
  }, [currentPage]);

  useEffect(() => {
    fetchAllSites();
  }, []);

  const fetchAllSites = async () => {
    setLoader(true);
    setSitesList([]);
    try {
      const response = await axiosInstance.get(
        `/categories/getAllCategories?limit=${PageSize}&pageNo=${currentPage}`
      );
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (response.data["success"] == true) {
        console.log(response.data.data.categories); // handle the response from the server
        setSitesList(response.data.data.categories);
        setTotalCount(response.data.data.count);
      }
    } catch (error) {
      console.log(error); // handle the error
      setLoader(false);
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    console.log("Site Data : ", JSON.stringify(sitesList));
  }, [sitesList]);

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const deleteSelectedSite = async () => {
    console.log("Selected Site : ", selectedCategory);
    try {
      const response = await axiosInstance.delete(
        `/categories/deleteCategories/${selectedCategory.id}`
      );
      if (response.data["success"] == true) {
        console.log(response); // handle the response from the server
        fetchAllSites();
        // setCurrentPage(0);
      }
    } catch (error) {
      console.log(error); // handle the error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let apiUrl = null;
    let isError = false;
    console.log(Object.keys(categoryData).length);
    if (Object.keys(categoryData).length > 0) {
      Object.keys(categoryData).map((item, index) => {
        if (item != "created_at" && item != "updated_at") {
          if (categoryData?.[item] == null || categoryData?.[item] == "") {
            const { name, value } = {
              name: item,
              value: `${item} is required!`,
            };
            setErrors({ ...errors, [name]: value });
            isError = true;
          }
        }
      });
    }
    if (isError) {
      setLoader(false);
      return;
    }
    if (Object.keys(selectedCategory).length > 0) {
      apiUrl = `/categories/updateCategories/${selectedCategory?.id}`;
    } else {
      apiUrl = "/categories/addCategories";
    }
    try {
      const response = await axiosInstance.post(apiUrl, categoryData);
      if (response.data["success"] == true) {
        fetchAllSites();
        // setCurrentPage(0);
        setSelectedCategory({});
        setCategoryData({});
        setOpenForm(false);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error); // handle the error
      setLoader(false);
      if (error?.response?.status == 412) {
        setErrors(error?.response?.data?.data);
      }
    }
  };

  return (
    <>
      <h1 className="heading"></h1>
      <div
        className="relative overflow-x-auto sm:rounded-lg"
        style={{ width: "100%", padding: "20px" }}
      >
        {" "}
        {loader ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className="bg-white tableDiv">
              <div className="">
                <CheckPermission permission="ADD_CATEGORY">
                  <button
                    onClick={() => {
                      setOpenForm(true);
                      setSelectedCategory({});
                    }}
                    className="btn-sm w-16 float-right"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      alignItems: "center",
                      width: "100px",
                      background: "#814f68"
                    }}
                  >
                    <span style={{ fontSize: "24px" }}>+</span> Add
                    {/* Add New Category */}
                  </button>
                </CheckPermission>
              </div>

               <div className="ml-4">
                <h1 className="text-2xl py-6">Category</h1>
              </div> 
              {sitesList.length > 0 ? (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  bg-white">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        {/* <input type="checkbox"></input> */}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Category name
                      </th>
                      <CheckPermission permission="SHOW_CATEGORY_ACTION">
                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </CheckPermission>
                    </tr>
                  </thead>
                  <tbody>
                    {sitesList.length > 0 ? (
                      Object.values(sitesList)?.map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        >
                          <td scope="col" className="px-6 py-3">
                            {/* <input type="checkbox"></input> */}
                            {index + 1}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.name}
                          </td>
                          <CheckPermission permission="SHOW_CATEGORY_ACTION">
                            <td className="px-6 py-4">
                              <div className="row align-item-center justify-space-space">
                                <a
                                  href="#"
                                  onClick={() => {
                                    setCategoryData(item);
                                    setOpenForm(true);
                                    setSelectedCategory(item);
                                    setErrors({});
                                  }}
                                  className="font-medium text-[#814f68]-600 dark:text-[#814f68]-500 hover:underline ml-4  "
                                >
                                  Edit
                                </a>

                                <a
                                  href="#"
                                  className="font-medium text-red-600 dark:text-blue-500 hover:underline ml-4"
                                  onClick={() => {
                                    setSubmissionModal(true);
                                    setSelectedCategory(item);
                                  }}
                                >
                                  Delete
                                </a>
                              </div>
                            </td>
                          </CheckPermission>
                        </tr>
                      ))
                    ) : (
                      <NoData />
                    )}
                  </tbody>
                </table>
              ) : (
                <NoData />
              )}
            </div>
            {
              sitesList.length > 0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                className="mb-2"
                totalPosts={totalCount}
                postsPerPage={PageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
              ):(
                <></>
              )
            }
          </>
        )}
        {submissionModal && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-20 z-50"
                style={{ width: "800px", maxWidth: "100%" }}
              >
                <div className="p-4 text-center sm:p-0 res">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5 res">
                      <div className="flex justify-between items-center res">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          Please Confirm
                        </h2>
                        {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                      </div>
                      <div>
                        <div className="flex py-10 justify-between items-center res">
                          <p className="text-[16px] font-bold helvetica not-italic mb-4 text-red-700">
                            Are you sure you want to delete?
                          </p>
                          <div className="flex gap-10 pr-4">
                            <button
                              className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#00617F] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                              onClick={async () => {
                                setSubmissionModal(false);
                                // nextStep();
                                await deleteSelectedSite(true);
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => {
                                setSubmissionModal(false);
                              }}
                              className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#00617F]"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {openForm && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-20 z-50"
                style={{ width: "600px", maxWidth: "90%" }}
              >
                <div className="p-4 text-center sm:p-0">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5">
                      <div className="flex justify-between items-center">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          {Object.keys(selectedCategory).length > 0
                            ? "Update Category"
                            : "Add New Category"}
                        </h2>
                        <button
                          className="ml-8"
                          onClick={() => {
                            setOpenForm(false);
                            setCategoryData({});
                          }}
                          type="reset"
                        >
                          <FaTimes />
                        </button>
                      </div>
                      <form onSubmit={handleSubmit} className="addsite">
                        <h6 className="small">
                          Category Name <span style={{ color: "red" }}>*</span>{" "}
                        </h6>

                        <input
                          className="textbox"
                          type="text"
                          name="name"
                          placeholder="Enter Category Name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={categoryData.name}
                        />
                        {errors.name && (
                          <p className="error-text">{errors.name}</p>
                        )}
                        <div className="flex w-100 gap-5 justify-space-space px-10 mt-4">
                            <button
                              className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#00617F] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                              
                              type="submit"
                            >
                              {Object.keys(selectedCategory).length > 0 ? (
                            
                              <span>Update</span>
                          ) : (
                              <span>Submit</span>
                          )}
                            </button>
                            <button
                              onClick={() => {
                                setOpenForm(false);
                              }}
                              type="reset"
                              className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                            >
                              Close
                            </button>
                          </div>
                        
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Category;
