
import React from 'react'
import img1 from "../assests/undraw_No_data_re_kwbl.png";
 import "./pagination.css"

const NoData = () => {
  return (
    <>
      <div className='NoDataDiv'>
      <img src={img1} className='NodataImg'></img>
        <h1 className='text-2xl text-color-grey'>No Data Found</h1>
      </div>
    </>
  )
}

export default NoData