import React, { useState, useEffect } from "react";
import "../../src/Table.css";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/PaginationComponent";
import axiosInstance from "../services/axios";
import "../pages/Addsite.css";
import img1 from "../assests/empty1.png";
import Loader from "../components/Loader";
import NoData from "../components/NoData";
import Select from "react-select";
import { FaTimes, FaAngleDown, FaRedoAlt } from "react-icons/fa";

let PageSize = 10;
const InventoryLog = ({ type }) => {
  const navigate = useNavigate();

  const [sitesList, setSitesList] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [selectedSite] = useState({});
  const [stockItemId, setStockItemId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    stock_item_id: null,
  });
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [sitesOptions, setSitesOption] = useState([]);
  const [filterSite, setFilterSite] = useState(null);

  useEffect(() => {
    console.log("selectedProduct : ", selectedProduct);
    fetchAllSites();
  }, [currentPage, selectedProduct]);

  useEffect(() => {
    fetchAllSites();
    fetchllProduct();
  }, []);

  const fetchAllSites = async () => {
    setSitesList([]);
    setLoader(true);
    try {
      let url = `/inventorylog/getAllInventoryLog?limit=${PageSize}&pageNo=${currentPage}`;
      if (type != null) {
        url = `${url}&type=${type}`;
      }
      if (selectedProduct) {
        url = `${url}&stock_item_id=${selectedProduct?.value}`;
      }
      if (filterSite) {
        url = `${url}&site_id=${filterSite?.value}`;
      }
      const response = await axiosInstance.get(url);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (response.data["success"] == true) {
        console.log(response.data.data.inventory_log); // handle the response from the server
        setSitesList(response.data.data.inventory_log);
        setTotalCount(response.data.data.count);
        setLoader(false);
      }
    } catch (error) {
      console.log(error); // handle the error
      setLoader(false);
      if (error?.response?.data?.message == "Token is Expired") {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const fetchllProduct = async () => {
    setProductOptions([]);
    setLoader(true);
    try {
      let url = `/products/getAllProducts`;
      const response = await axiosInstance.get(url);
      const siteResponse = await axiosInstance.get(`/sites/getAllSites`);
      if (response.data["success"] == true) {
        const options = [];
        response.data.data.products.map((item, index) => {
          options.push({ value: item?.id, label: item?.name });
        });
        setProductOptions(options);
      }
      if (siteResponse.data["success"] === true) {
        const options = [];
        siteResponse.data.data.sites.map((item, index) => {
          options.push({ value: item?.id, label: item?.name });
        });
        setSitesOption(options);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.data?.message == "Token is Expired") {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const deleteSelectedSite = async () => {
    console.log("Selected Site : ", selectedSite);
    try {
      const response = await axiosInstance.delete(
        `/sites/deleteSite/${selectedSite.id}`
      );
      if (response.data["success"] == true) {
        console.log(response); // handle the response from the server
        fetchAllSites();
      }
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.data?.message == "Token is Expired") {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  // const handleChange = (e) => {
  //   setStockItemId(e.target.value);
  // };

  const customstyles = {
    // control represent the select component
    control: (provided) => ({
      ...provided,
      width: "300px",
      height: "50px",
      border: "1px solid",
      borderRadius: "5px",
    }),
  };

  useEffect(() => {
    setCurrentPage(0);
    fetchAllSites();
  }, [filterOptions]);

  const handleFilterChange = (e, type) => {
    if (type === "product") {
      setSelectedProduct(e);
      setFilterOptions({...filterOptions, stock_item_id: e?.value });
    }
    if (type === "site") {
      setFilterOptions({...filterOptions, site_id: e?.value });
      setFilterSite(e);
    }
  };

  return (
    <>
      <h1 className="heading"></h1>
      <div
        className="relative overflow-x-auto sm:rounded-lg"
        style={{ width: "100%", padding: "20px" }}
      >
        {loader ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className="bg-white tableDiv">
              {/* <div
                className="float-right"
                style={{ marginBottom: "1rem", marginRight: "2rem" }}
              >
                <h6 className="small">Select Product</h6>
                <Select
                  defaultValue={selectedProduct}
                  onChange={setSelectedProduct}
                  options={productOptions}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "blue",
                      primary: "black",
                    },
                  })}
                  styles={customstyles}
                />
              </div> */}
              <div>
                <div className="ml-4">
                  <h1 className="text-2xl py-6 ">
                    {type == "increase" ? "Inward" : "Outward"} Logs
                  </h1>
                </div>
                <div
                  className="flex row align-item-center bg-gray-50 px-5"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    height: "30px",
                    borderBottom: "5px solid #fff",
                  }}
                >
                  <button
                    className=""
                    onClick={() => {
                      setFilterOptions(null);
                      setSelectedProduct(null);
                      setFilterSite(null);
                    }}
                    type="reset"
                  >
                    <FaRedoAlt />
                  </button>
                  <button
                    className=""
                    onClick={() => {
                      setShowFilterOptions(!showFilterOptions);
                    }}
                    type="reset"
                  >
                    <FaAngleDown />
                  </button>
                </div>
                {showFilterOptions ? (
                  <div
                    className="row-col px-5 align-item-center"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="colFour">
                      <h6 className="small">Select Product</h6>
                      <Select
                        defaultValue={selectedProduct}
                        value={selectedProduct}
                        onChange={(e) => {
                          handleFilterChange(e, "product");
                        }}
                        options={productOptions}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#814f68",
                            primary: "#814f68",
                          },
                        })}
                        styles={customstyles}
                      />
                    </div>
                    <div className="colFour">
                    <h6 className="small">Select Site</h6>
                    <Select
                      defaultValue={filterSite}
                      value={filterSite}
                      onChange={(e) => {
                        handleFilterChange(e, "site");
                      }}
                      options={sitesOptions}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#814f68",
                          primary: "#814f68",
                        },
                      })}
                      styles={customstyles}
                    />
                  </div>
                  </div>
                ) : (
                  <></>
                )}
                {sitesList.length > 0 ? (
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  bg-white">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          {/* <input type="checkbox"></input> */}
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Product name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Quantity
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Site Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Log Type
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ height: "40vh" }}>
                      {sitesList.length > 0 ? (
                        Object.values(sitesList)?.map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                          >
                            <td scope="col" className="px-6 py-3">
                              {/* <input type="checkbox"></input> */}
                              {index + 1}
                            </td>
                            <td
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item?.stock_details?.name} <br></br>
                              {item?.stock_details?.item_code}
                            </td>
                            <td
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.qty}
                            </td>
                            <td
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.site_details ? item.site_details?.name : 'N/A'}
                            </td>
                            <td
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.type}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoData />
                      )}
                    </tbody>
                  </table>
                ) : (
                  <NoData></NoData>
                )}
              </div>
            </div>
            {sitesList.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  className="mb-2"
                  totalPosts={totalCount}
                  postsPerPage={PageSize}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InventoryLog;
