import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Dashboard1 from "../routes/Dashboard1";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import "../App.css";

const SharedLayout = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")?.length == undefined) {
      navigate("/login");
    }
  });

  return (
    <>
      <div style={{display:"flex",flexDirection:"row"}}>
        <Dashboard1 setLoader={setLoader} />
        {
          loader ? (
            <div className="sharedLayoutLoader" >
              <Loader />
            </div>
          ) : (
            <div style={{ display:'flex', flexDirection:'column', width:'100%' }} >
              <div style={{ minHeight:'580px' }} >
                <Outlet />
              </div>
              <Footer></Footer>
            </div>
          )
        }
      </div>
      
    </>
  );
};

export default SharedLayout;
